package com.zegreatrob.coupling.action.secret

import com.zegreatrob.coupling.model.party.Secret
import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.Pair
import kotlin.String
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class CreateSecretCommandWrapper(
  override val action: CreateSecretCommand,
) : SuspendAction<CreateSecretCommand.Dispatcher, Pair<Secret, String>?>,
    ActionWrapper<CreateSecretCommand.Dispatcher, CreateSecretCommand> {
  override val dispatcherType: KClass<CreateSecretCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.secret.CreateSecretCommand.Dispatcher::class

  override suspend fun execute(dispatcher: CreateSecretCommand.Dispatcher): Pair<Secret, String>? = dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: CreateSecretCommand.Dispatcher, action: CreateSecretCommand): Pair<Secret, String>? = execute(dispatcher, ::CreateSecretCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<CreateSecretCommand.Dispatcher>, action: CreateSecretCommand): Pair<Secret, String>? = cannon.fire(::CreateSecretCommandWrapper.invoke(action))

public suspend fun ActionCannon<CreateSecretCommand.Dispatcher>.fire(action: CreateSecretCommand): Pair<Secret, String>? = fire(::CreateSecretCommandWrapper.invoke(action))

public fun CreateSecretCommand.wrap(): CreateSecretCommandWrapper = ::CreateSecretCommandWrapper.invoke(this)

public operator fun <R> ((CreateSecretCommandWrapper) -> R).invoke(action: CreateSecretCommand): R = this(::CreateSecretCommandWrapper.invoke(action))

public fun <R> call(function: (CreateSecretCommandWrapper) -> R, action: CreateSecretCommand): R = function(::CreateSecretCommandWrapper.invoke(action))

public fun <R> CreateSecretCommand.let(block: (CreateSecretCommandWrapper) -> R): R = block(::CreateSecretCommandWrapper.invoke(this))
