package com.zegreatrob.coupling.action.pin

import com.zegreatrob.coupling.action.VoidResult
import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class SavePinCommandWrapper(
  override val action: SavePinCommand,
) : SuspendAction<SavePinCommand.Dispatcher, VoidResult>,
    ActionWrapper<SavePinCommand.Dispatcher, SavePinCommand> {
  override val dispatcherType: KClass<SavePinCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.pin.SavePinCommand.Dispatcher::class

  override suspend fun execute(dispatcher: SavePinCommand.Dispatcher): VoidResult = dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: SavePinCommand.Dispatcher, action: SavePinCommand): VoidResult = execute(dispatcher, ::SavePinCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<SavePinCommand.Dispatcher>, action: SavePinCommand): VoidResult = cannon.fire(::SavePinCommandWrapper.invoke(action))

public suspend fun ActionCannon<SavePinCommand.Dispatcher>.fire(action: SavePinCommand): VoidResult = fire(::SavePinCommandWrapper.invoke(action))

public fun SavePinCommand.wrap(): SavePinCommandWrapper = ::SavePinCommandWrapper.invoke(this)

public operator fun <R> ((SavePinCommandWrapper) -> R).invoke(action: SavePinCommand): R = this(::SavePinCommandWrapper.invoke(action))

public fun <R> call(function: (SavePinCommandWrapper) -> R, action: SavePinCommand): R = function(::SavePinCommandWrapper.invoke(action))

public fun <R> SavePinCommand.let(block: (SavePinCommandWrapper) -> R): R = block(::SavePinCommandWrapper.invoke(this))
