(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"), require("ReactRouter"), require("ReactRouterDOM"), require("emotion"), require("empty"), require("html2canvas"), require("md5"), require("reactAuth0"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactDOM", "ReactRouter", "ReactRouterDOM", "emotion", "empty", "html2canvas", "md5", "reactAuth0"], factory);
	else if(typeof exports === 'object')
		exports["client"] = factory(require("React"), require("ReactDOM"), require("ReactRouter"), require("ReactRouterDOM"), require("emotion"), require("empty"), require("html2canvas"), require("md5"), require("reactAuth0"));
	else
		root["client"] = factory(root["React"], root["ReactDOM"], root["ReactRouter"], root["ReactRouterDOM"], root["emotion"], root["empty"], root["html2canvas"], root["md5"], root["reactAuth0"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__4883__, __WEBPACK_EXTERNAL_MODULE__1845__, __WEBPACK_EXTERNAL_MODULE__1242__, __WEBPACK_EXTERNAL_MODULE__854__, __WEBPACK_EXTERNAL_MODULE__5609__, __WEBPACK_EXTERNAL_MODULE__8513__, __WEBPACK_EXTERNAL_MODULE__2701__, __WEBPACK_EXTERNAL_MODULE__1308__, __WEBPACK_EXTERNAL_MODULE__3059__) => {
return 