package com.zegreatrob.coupling.action.pairassignmentdocument

import com.zegreatrob.coupling.action.VoidResult
import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class DeletePairAssignmentsCommandWrapper(
  override val action: DeletePairAssignmentsCommand,
) : SuspendAction<DeletePairAssignmentsCommand.Dispatcher, VoidResult>,
    ActionWrapper<DeletePairAssignmentsCommand.Dispatcher, DeletePairAssignmentsCommand> {
  override val dispatcherType: KClass<DeletePairAssignmentsCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.pairassignmentdocument.DeletePairAssignmentsCommand.Dispatcher::class

  override suspend fun execute(dispatcher: DeletePairAssignmentsCommand.Dispatcher): VoidResult = dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: DeletePairAssignmentsCommand.Dispatcher, action: DeletePairAssignmentsCommand): VoidResult = execute(dispatcher, ::DeletePairAssignmentsCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<DeletePairAssignmentsCommand.Dispatcher>, action: DeletePairAssignmentsCommand): VoidResult = cannon.fire(::DeletePairAssignmentsCommandWrapper.invoke(action))

public suspend fun ActionCannon<DeletePairAssignmentsCommand.Dispatcher>.fire(action: DeletePairAssignmentsCommand): VoidResult = fire(::DeletePairAssignmentsCommandWrapper.invoke(action))

public fun DeletePairAssignmentsCommand.wrap(): DeletePairAssignmentsCommandWrapper = ::DeletePairAssignmentsCommandWrapper.invoke(this)

public operator fun <R> ((DeletePairAssignmentsCommandWrapper) -> R).invoke(action: DeletePairAssignmentsCommand): R = this(::DeletePairAssignmentsCommandWrapper.invoke(action))

public fun <R> call(function: (DeletePairAssignmentsCommandWrapper) -> R, action: DeletePairAssignmentsCommand): R = function(::DeletePairAssignmentsCommandWrapper.invoke(action))

public fun <R> DeletePairAssignmentsCommand.let(block: (DeletePairAssignmentsCommandWrapper) -> R): R = block(::DeletePairAssignmentsCommandWrapper.invoke(this))
