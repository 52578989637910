package com.zegreatrob.coupling.json

@kotlinx.serialization.Serializable data class GqlPartyDetails(
                val id: String,
    val name: String?,
    val email: String?,
    val pairingRule: Int?,
    val badgesEnabled: Boolean?,
    val defaultBadgeName: String,
    val alternateBadgeName: String,
    val callSignsEnabled: Boolean?,
    val animationsEnabled: Boolean?,
    val animationSpeed: Double?,
    val modifyingUserEmail: String?,
    val timestamp: kotlinx.datetime.Instant?,
    val isDeleted: Boolean?
        )

@kotlinx.serialization.Serializable data class GqlPartyIntegration(
                val slackTeam: String?,
    val slackChannel: String?,
    val modifyingUserEmail: String,
    val timestamp: kotlinx.datetime.Instant,
    val isDeleted: Boolean
        )

@kotlinx.serialization.Serializable data class GqlUser(
                val id: String,
    val details: GqlUserDetails?,
    val boost: GqlBoostDetails?,
    val subscription: GqlSubscriptionDetails?
        )

@kotlinx.serialization.Serializable data class GqlUserDetails(
                val id: String,
    val email: String,
    val authorizedPartyIds: List<String>
        )

@kotlinx.serialization.Serializable data class GqlPin(
                val id: String,
    val icon: String?,
    val name: String?
        )

@kotlinx.serialization.Serializable data class GqlPinDetails(
                val id: String,
    val partyId: String,
    val icon: String,
    val name: String,
    val modifyingUserEmail: String,
    val timestamp: kotlinx.datetime.Instant,
    val isDeleted: Boolean
        )

@kotlinx.serialization.Serializable data class GqlPlayerDetails(
                val id: String,
    val partyId: String,
    val name: String,
    val email: String,
    val badge: String?,
    val callSignAdjective: String,
    val callSignNoun: String,
    val imageURL: String?,
    val avatarType: GqlAvatarType?,
    val unvalidatedEmails: List<String>,
    val modifyingUserEmail: String,
    val timestamp: kotlinx.datetime.Instant,
    val isDeleted: Boolean
        )

@kotlinx.serialization.Serializable enum class GqlAvatarType(val label: String) {
      @kotlinx.serialization.SerialName("Retro") Retro("Retro"),
      @kotlinx.serialization.SerialName("RobohashSet1") RobohashSet1("RobohashSet1"),
      @kotlinx.serialization.SerialName("RobohashSet2") RobohashSet2("RobohashSet2"),
      @kotlinx.serialization.SerialName("RobohashSet3") RobohashSet3("RobohashSet3"),
      @kotlinx.serialization.SerialName("RobohashSet4") RobohashSet4("RobohashSet4"),
      @kotlinx.serialization.SerialName("RobohashSet5") RobohashSet5("RobohashSet5"),
      @kotlinx.serialization.SerialName("BoringBeam") BoringBeam("BoringBeam"),
      @kotlinx.serialization.SerialName("BoringBauhaus") BoringBauhaus("BoringBauhaus"),
      @kotlinx.serialization.SerialName("Multiavatar") Multiavatar("Multiavatar"),
      @kotlinx.serialization.SerialName("DicebearPixelArt") DicebearPixelArt("DicebearPixelArt"),
      @kotlinx.serialization.SerialName("DicebearAdventurer") DicebearAdventurer("DicebearAdventurer"),
      @kotlinx.serialization.SerialName("DicebearCroodles") DicebearCroodles("DicebearCroodles"),
      @kotlinx.serialization.SerialName("DicebearThumbs") DicebearThumbs("DicebearThumbs"),
      @kotlinx.serialization.SerialName("DicebearLorelei") DicebearLorelei("DicebearLorelei");
        
  companion object {
    
    fun valueOfLabel(label: String): GqlAvatarType? {
      return values().find { it.label == label }
    }
  }
}

@kotlinx.serialization.Serializable data class GqlPinnedPlayer(
                val id: String,
    val name: String,
    val email: String,
    val badge: String?,
    val avatarType: GqlAvatarType?,
    val callSignAdjective: String,
    val callSignNoun: String,
    val imageURL: String?,
    val pins: List<GqlPin>,
    val unvalidatedEmails: List<String>?
        )

@kotlinx.serialization.Serializable data class GqlPinnedPair(
                val players: List<GqlPinnedPlayer>,
    val pins: List<GqlPin>
        )

@kotlinx.serialization.Serializable data class GqlPairAssignmentDocumentDetails(
                val id: String,
    val partyId: String,
    val date: kotlinx.datetime.Instant,
    val pairs: List<GqlPinnedPair>,
    val discordMessageId: String?,
    val slackMessageId: String?,
    val modifyingUserEmail: String,
    val timestamp: kotlinx.datetime.Instant,
    val isDeleted: Boolean
        )

@kotlinx.serialization.Serializable data class GqlPartySecret(
                val id: String,
    val partyId: String,
    val description: String,
    val createdTimestamp: kotlinx.datetime.Instant,
    val lastUsedTimestamp: kotlinx.datetime.Instant?,
    val modifyingUserEmail: String,
    val timestamp: kotlinx.datetime.Instant,
    val isDeleted: Boolean
        )

@kotlinx.serialization.Serializable data class GqlBoostDetails(
                val userId: String,
    val partyIds: List<String>,
    val expirationDate: kotlinx.datetime.Instant,
    val modifyingUserEmail: String,
    val timestamp: kotlinx.datetime.Instant,
    val isDeleted: Boolean
        )

@kotlinx.serialization.Serializable data class GqlContribution(
                val commitCount: Int?,
    val createdAt: kotlinx.datetime.Instant,
    val cycleTime: kotlin.time.Duration?,
    val dateTime: kotlinx.datetime.Instant?,
    val ease: Int?,
    val firstCommit: String?,
    val firstCommitDateTime: kotlinx.datetime.Instant?,
    val hash: String?,
    val id: String,
    val integrationDateTime: kotlinx.datetime.Instant?,
    val label: String?,
    val link: String?,
    val name: String?,
    val participantEmails: List<String>,
    val partyId: String?,
    val semver: String?,
    val story: String?,
    val modifyingUserEmail: String,
    val timestamp: kotlinx.datetime.Instant,
    val isDeleted: Boolean
        )

@kotlinx.serialization.Serializable data class GqlPartyContributionReportArgs(
                val input: GqlContributionsInput? = null
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  args["input"]?.let { GqlContributionsInput(it as Map<String, Any>) }
            )
        }
@kotlinx.serialization.Serializable data class GqlPartyPairArgs(
                val input: GqlPairInput? = null
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  args["input"]?.let { GqlPairInput(it as Map<String, Any>) }
            )
        }
@kotlinx.serialization.Serializable data class GqlParty(
                val id: String,
    val details: GqlPartyDetails?,
    val integration: GqlPartyIntegration?,
    val pinList: List<GqlPinDetails>?,
    val playerList: List<GqlPlayerDetails>?,
    val retiredPlayers: List<GqlPlayerDetails>?,
    val pairs: List<GqlPair>?,
    val pairAssignmentDocumentList: List<GqlPairAssignmentDocumentDetails>?,
    val secretList: List<GqlPartySecret>?,
    val currentPairAssignmentDocument: GqlPairAssignmentDocumentDetails?,
    val spinsUntilFullRotation: Int?,
    val medianSpinDuration: kotlin.time.Duration?,
    val boost: GqlBoostDetails?,
    val contributionReport: GqlContributionReport?,
    val pair: GqlPair?
        )

@kotlinx.serialization.Serializable data class GqlContributor(
                val email: String?,
    val details: GqlPlayerDetails?
        )

@kotlinx.serialization.Serializable data class GqlPairInput(
                val playerIdList: List<String>
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["playerIdList"] as List<String>
            )
        }

@kotlinx.serialization.Serializable data class GqlContributionsInput(
                val limit: Int? = null,
    val window: GqlContributionWindow? = null
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["limit"] as Int?,
      args["window"] as GqlContributionWindow?
            )
        }

@kotlinx.serialization.Serializable enum class GqlContributionWindow(val label: String) {
      @kotlinx.serialization.SerialName("All") All("All"),
      @kotlinx.serialization.SerialName("Year") Year("Year"),
      @kotlinx.serialization.SerialName("Quarter") Quarter("Quarter"),
      @kotlinx.serialization.SerialName("Month") Month("Month"),
      @kotlinx.serialization.SerialName("Week") Week("Week");
        
  companion object {
    
    fun valueOfLabel(label: String): GqlContributionWindow? {
      return values().find { it.label == label }
    }
  }
}

@kotlinx.serialization.Serializable data class GqlPlayerInput(
                val id: String,
    val name: String? = null,
    val email: String? = null,
    val badge: String? = null,
    val callSignAdjective: String? = null,
    val callSignNoun: String? = null,
    val imageURL: String? = null,
    val avatarType: String? = null
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["id"] as String,
      args["name"] as String?,
      args["email"] as String?,
      args["badge"] as String?,
      args["callSignAdjective"] as String?,
      args["callSignNoun"] as String?,
      args["imageURL"] as String?,
      args["avatarType"] as String?
            )
        }

@kotlinx.serialization.Serializable data class GqlPinInput(
                val id: String,
    val icon: String? = null,
    val name: String? = null
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["id"] as String,
      args["icon"] as String?,
      args["name"] as String?
            )
        }

@kotlinx.serialization.Serializable data class GqlSpinInput(
                val partyId: String,
    val playerIds: List<String>,
    val pinIds: List<String>
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["playerIds"] as List<String>,
      args["pinIds"] as List<String>
            )
        }

@kotlinx.serialization.Serializable data class GqlSpinOutput(
                val result: GqlPairAssignmentDocumentDetails
        )

@kotlinx.serialization.Serializable data class GqlDeletePartyInput(
                val partyId: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlDeletePinInput(
                val partyId: String,
    val pinId: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["pinId"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlDeletePlayerInput(
                val partyId: String,
    val playerId: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["playerId"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlDeletePairAssignmentsInput(
                val partyId: String,
    val pairAssignmentsId: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["pairAssignmentsId"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlSavePinInput(
                val partyId: String,
    val pinId: String? = null,
    val icon: String,
    val name: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["pinId"] as String?,
      args["icon"] as String,
      args["name"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlSaveSlackIntegrationInput(
                val partyId: String,
    val team: String,
    val channel: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["team"] as String,
      args["channel"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlSavePartyInput(
                val partyId: String,
    val name: String? = null,
    val email: String? = null,
    val pairingRule: Int? = null,
    val badgesEnabled: Boolean? = null,
    val defaultBadgeName: String? = null,
    val alternateBadgeName: String? = null,
    val callSignsEnabled: Boolean? = null,
    val animationsEnabled: Boolean? = null,
    val animationSpeed: Double? = null
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["name"] as String?,
      args["email"] as String?,
      args["pairingRule"] as Int?,
      args["badgesEnabled"] as Boolean?,
      args["defaultBadgeName"] as String?,
      args["alternateBadgeName"] as String?,
      args["callSignsEnabled"] as Boolean?,
      args["animationsEnabled"] as Boolean?,
      args["animationSpeed"] as Double?
            )
        }

@kotlinx.serialization.Serializable data class GqlSavePlayerInput(
                val partyId: String,
    val playerId: String,
    val name: String,
    val email: String,
    val badge: String? = null,
    val callSignAdjective: String,
    val callSignNoun: String,
    val imageURL: String? = null,
    val avatarType: String? = null,
    val unvalidatedEmails: List<String>
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["playerId"] as String,
      args["name"] as String,
      args["email"] as String,
      args["badge"] as String?,
      args["callSignAdjective"] as String,
      args["callSignNoun"] as String,
      args["imageURL"] as String?,
      args["avatarType"] as String?,
      args["unvalidatedEmails"] as List<String>
            )
        }

@kotlinx.serialization.Serializable data class GqlSavePairAssignmentsInput(
                val partyId: String,
    val pairAssignmentsId: String,
    val date: kotlinx.datetime.Instant,
    val pairs: List<GqlPinnedPairInput>,
    val discordMessageId: String? = null,
    val slackMessageId: String? = null
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["pairAssignmentsId"] as String,
      args["date"] as kotlinx.datetime.Instant,
      args["pairs"]!!.let { pairs -> (pairs as List<Map<String, Any>>).map { GqlPinnedPairInput(it) } },
      args["discordMessageId"] as String?,
      args["slackMessageId"] as String?
            )
        }

@kotlinx.serialization.Serializable data class GqlPinnedPairInput(
                val players: List<GqlPinnedPlayerInput>,
    val pins: List<GqlPinInput>
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  args["players"]!!.let { players -> (players as List<Map<String, Any>>).map { GqlPinnedPlayerInput(it) } },
      args["pins"]!!.let { pins -> (pins as List<Map<String, Any>>).map { GqlPinInput(it) } }
            )
        }

@kotlinx.serialization.Serializable data class GqlPinnedPlayerInput(
                val id: String,
    val name: String,
    val email: String,
    val badge: String? = null,
    val callSignAdjective: String,
    val callSignNoun: String,
    val imageURL: String? = null,
    val avatarType: GqlAvatarType? = null,
    val unvalidatedEmails: List<String>,
    val pins: List<GqlPinInput>
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  args["id"] as String,
      args["name"] as String,
      args["email"] as String,
      args["badge"] as String?,
      args["callSignAdjective"] as String,
      args["callSignNoun"] as String,
      args["imageURL"] as String?,
      args["avatarType"] as GqlAvatarType?,
      args["unvalidatedEmails"] as List<String>,
      args["pins"]!!.let { pins -> (pins as List<Map<String, Any>>).map { GqlPinInput(it) } }
            )
        }

@kotlinx.serialization.Serializable data class GqlGlobalStats(
                val parties: List<GqlPartyStats>,
    val totalParties: Int,
    val totalPlayers: Int,
    val totalAppliedPins: Int,
    val totalUniquePins: Int,
    val totalSpins: Int
        )

@kotlinx.serialization.Serializable data class GqlPartyStats(
                val name: String,
    val id: String,
    val playerCount: Int,
    val appliedPinCount: Int,
    val uniquePinCount: Int,
    val spins: Int,
    val medianSpinDuration: kotlin.time.Duration?,
    val medianSpinDurationMillis: Double?
        )

@kotlinx.serialization.Serializable data class GqlConfiguration(
                val discordClientId: String?,
    val addToSlackUrl: String?,
    val stripeAdminCode: String?,
    val stripePurchaseCode: String?
        )

@kotlinx.serialization.Serializable data class GqlGlobalStatsInput(
                val year: Int
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["year"] as Int
            )
        }

@kotlinx.serialization.Serializable data class GqlPartyInput(
                val partyId: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlPairContributionReportArgs(
                val input: GqlContributionsInput? = null
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  args["input"]?.let { GqlContributionsInput(it as Map<String, Any>) }
            )
        }
@kotlinx.serialization.Serializable data class GqlPair(
                val partyId: String?,
    val players: List<GqlPlayerDetails>?,
    val count: Int?,
    val spinsSinceLastPaired: Int?,
    val recentTimesPaired: Int?,
    val contributionReport: GqlContributionReport?,
    val pairAssignmentHistory: List<GqlPairAssignment>?
        )

@kotlinx.serialization.Serializable data class GqlContributionReport(
                val partyId: String?,
    val contributions: List<GqlContribution>?,
    val count: Int?,
    val medianCycleTime: kotlin.time.Duration?,
    val withCycleTimeCount: Int?,
    val contributors: List<GqlContributor>?
        )

@kotlinx.serialization.Serializable data class GqlPairAssignment(
                val playerIds: List<String>?,
    val documentId: String?,
    val details: GqlPairAssignmentDocumentDetails?,
    val date: kotlinx.datetime.Instant?,
    val allPairs: List<GqlPinnedPair>?,
    val recentTimesPaired: Int?
        )

@kotlinx.serialization.Serializable data class GqlQueryPartyArgs(
                val input: GqlPartyInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlPartyInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlQueryGlobalStatsArgs(
                val input: GqlGlobalStatsInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlGlobalStatsInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlQuery(
                val partyList: List<GqlParty>?,
    val party: GqlParty?,
    val user: GqlUser?,
    val globalStats: GqlGlobalStats?,
    val config: GqlConfiguration?
        )

@kotlinx.serialization.Serializable data class GqlMutationSpinArgs(
                val input: GqlSpinInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlSpinInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationSavePartyArgs(
                val input: GqlSavePartyInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlSavePartyInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationDeletePartyArgs(
                val input: GqlDeletePartyInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlDeletePartyInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationSaveSlackIntegrationArgs(
                val input: GqlSaveSlackIntegrationInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlSaveSlackIntegrationInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationSavePinArgs(
                val input: GqlSavePinInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlSavePinInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationDeletePinArgs(
                val input: GqlDeletePinInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlDeletePinInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationSavePlayerArgs(
                val input: GqlSavePlayerInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlSavePlayerInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationDeletePlayerArgs(
                val input: GqlDeletePlayerInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlDeletePlayerInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationSavePairAssignmentsArgs(
                val input: GqlSavePairAssignmentsInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlSavePairAssignmentsInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationDeletePairAssignmentsArgs(
                val input: GqlDeletePairAssignmentsInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlDeletePairAssignmentsInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationCreateSecretArgs(
                val input: GqlCreateSecretInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlCreateSecretInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationDeleteSecretArgs(
                val input: GqlDeleteSecretInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlDeleteSecretInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationSaveContributionArgs(
                val input: GqlSaveContributionInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlSaveContributionInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationClearContributionsArgs(
                val input: GqlClearContributionsInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlClearContributionsInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationGrantSlackAccessArgs(
                val input: GqlGrantSlackAccessInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlGrantSlackAccessInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationGrantDiscordAccessArgs(
                val input: GqlGrantDiscordAccessInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlGrantDiscordAccessInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutationSaveBoostArgs(
                val input: GqlSaveBoostInput
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  GqlSaveBoostInput(args["input"] as Map<String, Any>)
            )
        }
@kotlinx.serialization.Serializable data class GqlMutation(
                val spin: Boolean?,
    val saveParty: Boolean?,
    val deleteParty: Boolean?,
    val saveSlackIntegration: Boolean?,
    val savePin: Boolean?,
    val deletePin: Boolean?,
    val savePlayer: Boolean?,
    val deletePlayer: Boolean?,
    val savePairAssignments: Boolean?,
    val deletePairAssignments: Boolean?,
    val createSecret: GqlSecretToken?,
    val deleteSecret: Boolean?,
    val saveContribution: Boolean?,
    val clearContributions: Boolean?,
    val grantSlackAccess: Boolean?,
    val grantDiscordAccess: Boolean?,
    val saveBoost: Boolean?,
    val deleteBoost: Boolean?
        )

@kotlinx.serialization.Serializable data class GqlSaveContributionInput(
                val partyId: String,
    val contributionId: String? = null,
    val hash: String? = null,
    val dateTime: String? = null,
    val ease: Int? = null,
    val story: String? = null,
    val link: String? = null,
    val label: String? = null,
    val semver: String? = null,
    val firstCommit: String? = null,
    val firstCommitDateTime: String? = null,
    val integrationDateTime: String? = null,
    val cycleTime: String? = null,
    val participantEmails: List<String>? = null,
    val contributionList: List<GqlContributionInput>
        ) {
            @Suppress("UNCHECKED_CAST")
  constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["contributionId"] as String?,
      args["hash"] as String?,
      args["dateTime"] as String?,
      args["ease"] as Int?,
      args["story"] as String?,
      args["link"] as String?,
      args["label"] as String?,
      args["semver"] as String?,
      args["firstCommit"] as String?,
      args["firstCommitDateTime"] as String?,
      args["integrationDateTime"] as String?,
      args["cycleTime"] as String?,
      args["participantEmails"] as List<String>?,
      args["contributionList"]!!.let { contributionList -> (contributionList as List<Map<String, Any>>).map { GqlContributionInput(it) } }
            )
        }

@kotlinx.serialization.Serializable data class GqlContributionInput(
                val contributionId: String,
    val hash: String? = null,
    val dateTime: kotlinx.datetime.Instant? = null,
    val ease: Int? = null,
    val story: String? = null,
    val link: String? = null,
    val label: String? = null,
    val semver: String? = null,
    val firstCommit: String? = null,
    val firstCommitDateTime: kotlinx.datetime.Instant? = null,
    val integrationDateTime: kotlinx.datetime.Instant? = null,
    val cycleTime: kotlin.time.Duration? = null,
    val participantEmails: List<String>,
    val name: String? = null,
    val commitCount: Int? = null
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["contributionId"] as String,
      args["hash"] as String?,
      args["dateTime"] as kotlinx.datetime.Instant?,
      args["ease"] as Int?,
      args["story"] as String?,
      args["link"] as String?,
      args["label"] as String?,
      args["semver"] as String?,
      args["firstCommit"] as String?,
      args["firstCommitDateTime"] as kotlinx.datetime.Instant?,
      args["integrationDateTime"] as kotlinx.datetime.Instant?,
      args["cycleTime"] as kotlin.time.Duration?,
      args["participantEmails"] as List<String>,
      args["name"] as String?,
      args["commitCount"] as Int?
            )
        }

@kotlinx.serialization.Serializable data class GqlClearContributionsInput(
                val partyId: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlGrantSlackAccessInput(
                val code: String,
    val state: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["code"] as String,
      args["state"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlGrantDiscordAccessInput(
                val code: String,
    val partyId: String,
    val guildId: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["code"] as String,
      args["partyId"] as String,
      args["guildId"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlCreateSecretInput(
                val partyId: String,
    val description: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["description"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlSecretToken(
                val secretId: String,
    val description: String,
    val createdTimestamp: kotlinx.datetime.Instant,
    val lastUsedTimestamp: kotlinx.datetime.Instant?,
    val secretToken: String
        )

@kotlinx.serialization.Serializable data class GqlDeleteSecretInput(
                val partyId: String,
    val secretId: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String,
      args["secretId"] as String
            )
        }

@kotlinx.serialization.Serializable data class GqlSubscriptionDetails(
                val stripeCustomerId: String?,
    val stripeSubscriptionId: String?,
    val isActive: Boolean,
    val currentPeriodEnd: kotlinx.datetime.Instant?
        )

@kotlinx.serialization.Serializable data class GqlSaveBoostInput(
                val partyId: String
        ) {
            constructor(args: Map<String, Any>) : this(
                  args["partyId"] as String
            )
        }