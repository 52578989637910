package com.zegreatrob.coupling.action.pin

import com.zegreatrob.coupling.action.VoidResult
import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class DeletePinCommandWrapper(
  override val action: DeletePinCommand,
) : SuspendAction<DeletePinCommand.Dispatcher, VoidResult>,
    ActionWrapper<DeletePinCommand.Dispatcher, DeletePinCommand> {
  override val dispatcherType: KClass<DeletePinCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.pin.DeletePinCommand.Dispatcher::class

  override suspend fun execute(dispatcher: DeletePinCommand.Dispatcher): VoidResult = dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: DeletePinCommand.Dispatcher, action: DeletePinCommand): VoidResult = execute(dispatcher, ::DeletePinCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<DeletePinCommand.Dispatcher>, action: DeletePinCommand): VoidResult = cannon.fire(::DeletePinCommandWrapper.invoke(action))

public suspend fun ActionCannon<DeletePinCommand.Dispatcher>.fire(action: DeletePinCommand): VoidResult = fire(::DeletePinCommandWrapper.invoke(action))

public fun DeletePinCommand.wrap(): DeletePinCommandWrapper = ::DeletePinCommandWrapper.invoke(this)

public operator fun <R> ((DeletePinCommandWrapper) -> R).invoke(action: DeletePinCommand): R = this(::DeletePinCommandWrapper.invoke(action))

public fun <R> call(function: (DeletePinCommandWrapper) -> R, action: DeletePinCommand): R = function(::DeletePinCommandWrapper.invoke(action))

public fun <R> DeletePinCommand.let(block: (DeletePinCommandWrapper) -> R): R = block(::DeletePinCommandWrapper.invoke(this))
