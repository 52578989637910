package com.zegreatrob.coupling.action.pairassignmentdocument

import com.zegreatrob.coupling.action.VoidResult
import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class SavePairAssignmentsCommandWrapper(
  override val action: SavePairAssignmentsCommand,
) : SuspendAction<SavePairAssignmentsCommand.Dispatcher, VoidResult>,
    ActionWrapper<SavePairAssignmentsCommand.Dispatcher, SavePairAssignmentsCommand> {
  override val dispatcherType: KClass<SavePairAssignmentsCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.pairassignmentdocument.SavePairAssignmentsCommand.Dispatcher::class

  override suspend fun execute(dispatcher: SavePairAssignmentsCommand.Dispatcher): VoidResult = dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: SavePairAssignmentsCommand.Dispatcher, action: SavePairAssignmentsCommand): VoidResult = execute(dispatcher, ::SavePairAssignmentsCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<SavePairAssignmentsCommand.Dispatcher>, action: SavePairAssignmentsCommand): VoidResult = cannon.fire(::SavePairAssignmentsCommandWrapper.invoke(action))

public suspend fun ActionCannon<SavePairAssignmentsCommand.Dispatcher>.fire(action: SavePairAssignmentsCommand): VoidResult = fire(::SavePairAssignmentsCommandWrapper.invoke(action))

public fun SavePairAssignmentsCommand.wrap(): SavePairAssignmentsCommandWrapper = ::SavePairAssignmentsCommandWrapper.invoke(this)

public operator fun <R> ((SavePairAssignmentsCommandWrapper) -> R).invoke(action: SavePairAssignmentsCommand): R = this(::SavePairAssignmentsCommandWrapper.invoke(action))

public fun <R> call(function: (SavePairAssignmentsCommandWrapper) -> R, action: SavePairAssignmentsCommand): R = function(::SavePairAssignmentsCommandWrapper.invoke(action))

public fun <R> SavePairAssignmentsCommand.let(block: (SavePairAssignmentsCommandWrapper) -> R): R = block(::SavePairAssignmentsCommandWrapper.invoke(this))
