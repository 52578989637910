package com.zegreatrob.coupling.action.pairassignmentdocument

import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class CreatePairCandidateReportActionWrapper(
  override val action: CreatePairCandidateReportAction,
) : SuspendAction<CreatePairCandidateReportAction.Dispatcher, PairCandidateReport>,
    ActionWrapper<CreatePairCandidateReportAction.Dispatcher, CreatePairCandidateReportAction> {
  override val dispatcherType: KClass<CreatePairCandidateReportAction.Dispatcher>
    get() = com.zegreatrob.coupling.action.pairassignmentdocument.CreatePairCandidateReportAction.Dispatcher::class

  override suspend fun execute(dispatcher: CreatePairCandidateReportAction.Dispatcher): PairCandidateReport = dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: CreatePairCandidateReportAction.Dispatcher, action: CreatePairCandidateReportAction): PairCandidateReport = execute(dispatcher, ::CreatePairCandidateReportActionWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<CreatePairCandidateReportAction.Dispatcher>, action: CreatePairCandidateReportAction): PairCandidateReport = cannon.fire(::CreatePairCandidateReportActionWrapper.invoke(action))

public suspend fun ActionCannon<CreatePairCandidateReportAction.Dispatcher>.fire(action: CreatePairCandidateReportAction): PairCandidateReport = fire(::CreatePairCandidateReportActionWrapper.invoke(action))

public fun CreatePairCandidateReportAction.wrap(): CreatePairCandidateReportActionWrapper = ::CreatePairCandidateReportActionWrapper.invoke(this)

public operator fun <R> ((CreatePairCandidateReportActionWrapper) -> R).invoke(action: CreatePairCandidateReportAction): R = this(::CreatePairCandidateReportActionWrapper.invoke(action))

public fun <R> call(function: (CreatePairCandidateReportActionWrapper) -> R, action: CreatePairCandidateReportAction): R = function(::CreatePairCandidateReportActionWrapper.invoke(action))

public fun <R> CreatePairCandidateReportAction.let(block: (CreatePairCandidateReportActionWrapper) -> R): R = block(::CreatePairCandidateReportActionWrapper.invoke(this))
