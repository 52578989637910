package com.zegreatrob.coupling.action.party

import com.zegreatrob.coupling.action.VoidResult
import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class DeletePartyCommandWrapper(
  override val action: DeletePartyCommand,
) : SuspendAction<DeletePartyCommand.Dispatcher, VoidResult>,
    ActionWrapper<DeletePartyCommand.Dispatcher, DeletePartyCommand> {
  override val dispatcherType: KClass<DeletePartyCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.party.DeletePartyCommand.Dispatcher::class

  override suspend fun execute(dispatcher: DeletePartyCommand.Dispatcher): VoidResult = dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: DeletePartyCommand.Dispatcher, action: DeletePartyCommand): VoidResult = execute(dispatcher, ::DeletePartyCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<DeletePartyCommand.Dispatcher>, action: DeletePartyCommand): VoidResult = cannon.fire(::DeletePartyCommandWrapper.invoke(action))

public suspend fun ActionCannon<DeletePartyCommand.Dispatcher>.fire(action: DeletePartyCommand): VoidResult = fire(::DeletePartyCommandWrapper.invoke(action))

public fun DeletePartyCommand.wrap(): DeletePartyCommandWrapper = ::DeletePartyCommandWrapper.invoke(this)

public operator fun <R> ((DeletePartyCommandWrapper) -> R).invoke(action: DeletePartyCommand): R = this(::DeletePartyCommandWrapper.invoke(action))

public fun <R> call(function: (DeletePartyCommandWrapper) -> R, action: DeletePartyCommand): R = function(::DeletePartyCommandWrapper.invoke(action))

public fun <R> DeletePartyCommand.let(block: (DeletePartyCommandWrapper) -> R): R = block(::DeletePartyCommandWrapper.invoke(this))
