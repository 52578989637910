package com.zegreatrob.coupling.action.player

import com.zegreatrob.coupling.action.VoidResult
import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class SavePlayerCommandWrapper(
  override val action: SavePlayerCommand,
) : SuspendAction<SavePlayerCommand.Dispatcher, VoidResult>,
    ActionWrapper<SavePlayerCommand.Dispatcher, SavePlayerCommand> {
  override val dispatcherType: KClass<SavePlayerCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.player.SavePlayerCommand.Dispatcher::class

  override suspend fun execute(dispatcher: SavePlayerCommand.Dispatcher): VoidResult = dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: SavePlayerCommand.Dispatcher, action: SavePlayerCommand): VoidResult = execute(dispatcher, ::SavePlayerCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<SavePlayerCommand.Dispatcher>, action: SavePlayerCommand): VoidResult = cannon.fire(::SavePlayerCommandWrapper.invoke(action))

public suspend fun ActionCannon<SavePlayerCommand.Dispatcher>.fire(action: SavePlayerCommand): VoidResult = fire(::SavePlayerCommandWrapper.invoke(action))

public fun SavePlayerCommand.wrap(): SavePlayerCommandWrapper = ::SavePlayerCommandWrapper.invoke(this)

public operator fun <R> ((SavePlayerCommandWrapper) -> R).invoke(action: SavePlayerCommand): R = this(::SavePlayerCommandWrapper.invoke(action))

public fun <R> call(function: (SavePlayerCommandWrapper) -> R, action: SavePlayerCommand): R = function(::SavePlayerCommandWrapper.invoke(action))

public fun <R> SavePlayerCommand.let(block: (SavePlayerCommandWrapper) -> R): R = block(::SavePlayerCommandWrapper.invoke(this))
